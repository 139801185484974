<template>
  <div v-if="isRoleF1">
    <!-- ANCHOR: Filters -->
    <!-- <PromotionUserFilters
      :type-filter.sync="typeFilter"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :airline.sync="airline"
      @fetch-data="refetchData"
      @reset="clearFilter"
    /> -->

    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      header-class="py-75"
    >
      <template #header>
        <h3 class="mb-0">
          {{ $t('promotionUser.list.title') }}
        </h3>
      </template>
      <!-- SECTION Table Top -->
      <div class="mx-2 my-50">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >

          <!-- ANCHOR size per page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <!-- <span class="text-muted ml-50">{{ $t('promotionAirline.promotions') }}</span> -->
          </b-col>

          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div>
              <!-- ANCHOR button create -->
              <!-- :disabled="!canAccess('employee.createEmployee')" -->
              <b-button
                class="px-lg-1 px-sm-75"
                variant="info"
                @click="() => $router.push({name: 'apps-promotionUser-create'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('promotionAirline.create.title') }}</span>
                </span>
              </b-button>
            </div>
          </b-col>

        </b-row>
      </div>
      <!-- !SECTION -->

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- SECTION Table Content -->
        <b-table
          ref="refPromotionsListTable"
          class="position-relative"
          :items="fetchPromotionUser"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
        >

          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`promotionUser.fields.${data.label}`) }}
            </span>
          </template>

          <template #cell(no)="data">
            <div class="text-nowrap">
              <span class="font-weight-bold">
                {{ ((currentPage - 1) * sizePerPage) + data.index + 1 }}
              </span>
            </div>
          </template>

          <template #cell(employee)="data">
            <div class="text-nowrap">
              <b-link
                :disabled="!canAccess('employee.detailEmployee')"
                :to="{
                  name: 'apps-employees-edit',
                  params: { id: data.item.employee.id },
                }"
                class="font-weight-bold d-inline text-nowrap text-info"
              >
                <span
                  class="align-text-top text-capitalize font-weight-bold"
                >
                  {{ data.item.employee.username }}
                </span>
              </b-link>
              <div class="text-black">
                ({{ data.item.employee.employeeCode }})
              </div>
            </div>
          </template>

          <template #cell(promoCode)="data">
            <div class="text-nowrap">
              <span
                class="align-text-top text-capitalize font-weight-bold text-info cursor-pointer"
                @click="handleOpenDetail(data.item)"
              >
                {{ data.item.promoCode }}
              </span>
            </div>
          </template>

          <template #cell(source)="data">
            <div class="d-flex-center">
              {{ $t(`flight.sourceName.${data.item.source}`) }}
            </div>
          </template>

          <template #cell(maxApply)="data">
            <div class="d-flex-center">
              {{ data.item.maxApply }}
            </div>
          </template>

          <template #cell(promoCodeApplied)="data">
            <div class="d-flex-center">
              {{ data.item.promoCodeApplied }}
            </div>
          </template>

          <template #cell(isActive)="data">
            <div class="d-flex-center">
              <b-form-checkbox
                v-model="data.item.isActive"
                class="mr-0 mt-50 custom-control-success"
                name="check-button"
                switch
                inline
                @click.native.prevent="changeStatus(data.item)"
              />
            </div>
          </template>

          <template #cell(createdAt)="data">
            <b-media
              no-body
              class="d-flex-center"
            >
              <b-media-aside class="mr-25">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
              </b-media-body>
            </b-media>
          </template>

          <template
            #cell(updatedAt)="data"
          >
            <b-media
              no-body
              class="d-flex-center"
            >
              <b-media-aside class="mr-25">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
              </b-media-body>
            </b-media>
          </template>
        </b-table>
        <!-- !SECTION -->
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-nowrap">{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }}
              <span class="d-inline-block d-sm-none d-md-inline"> {{ $t('paginationText.outOf') }} </span>
              <span class="d-none d-sm-inline d-md-none"> / </span>
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPromotions"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <PromotionUserEdit
      :promotion-data="promotionDetailData"
      @refetch-data="refetchData"
    />
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BMediaBody,
  BMediaAside,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onMounted, onUnmounted, ref, computed,
} from '@vue/composition-api'

import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'

import { dateTime } from '@core/utils/filter'

import usePromotionUserHandle from '@promotionUser/usePromotionUserHandle'
import promotionUserStoreModule from '@promotionUser/promotionUserStoreModule'

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BOverlay,
    PromotionUserEdit: () => import('@promotionUser/edit/PromotionUserEdit.vue'),
    vSelect,
  },
  setup() {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const PROMOTION_USER_APP_STORE_MODULE_NAME = 'app-promotionUser'

    // Register module
    if (!store.hasModule(PROMOTION_USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_USER_APP_STORE_MODULE_NAME, promotionUserStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_USER_APP_STORE_MODULE_NAME)
      }
    })

    const {
      fetchPromotionUser,
      tableColumns,
      sizePerPage,
      currentPage,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      clearFilter,
      toggleStatus,
      refetchData,
      // Loading
      loading,
    } = usePromotionUserHandle()

    function changeStatus(promotion) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            toggleStatus(promotion.id, !promotion.isActive)
          }
        })
    }

    const promotionDetailData = ref()

    function handleOpenDetail(promotionUser) {
      this.$bvModal.show('modal-promotion-user-detail')
      promotionDetailData.value = promotionUser
    }
    onMounted(() => { fetchPromotionUser() })

    return {
      // Sidebar
      changeStatus,
      fetchPromotionUser,
      dateTime,
      tableColumns,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      clearFilter,

      handleOpenDetail,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // select Options
      sizePerPageOptions,

      // Loading
      loading,

      promotionDetailData,
      refetchData,
      isRoleF1,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
